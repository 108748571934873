.text {
  color: white;
  font-family: "DM Sans", sans-serif;
}

.link {
  color: white;
  text-decoration: none;
}
/* .link:hover {
  color: black;
} */

.socials {
  margin-top: 5vh;
}

.twitter {
  height: 2.5vh;
}

.profile {
  height: 3.5vh;
  border-radius: 50%;
  margin-left: 15px;
}

.link-container {
  color: #fff !important;
  text-decoration: none;
  background: transparent;
  padding: 20px;
  border-radius: 50px;
  display: flex;
  border: none;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
}

.App {
  text-align: center;
  height: 100vh;
}

h1 {
  font-size: 1.5em;
}

.App-logo {
  pointer-events: none;
  max-width: 90%;
  height: 25vh;
}

.hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 5rem;
}

/* CSS */
.button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #cdcfdd;
  border-radius: 15px;
  box-sizing: border-box;
  color: #cdcfdd;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  margin-top: 60px;
}

.button:disabled {
  pointer-events: none;
}

.button:hover {
  color: #1a1a1a;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button:active {
  box-shadow: none;
  transform: translateY(0);
}

.App-header {
  background-color: #76a2e8;
  background: radial-gradient(
    circle,
    rgba(97, 97, 230, 1) 0%,
    rgba(14, 0, 255, 0.8281906512605042) 37%,
    rgba(0, 23, 36, 0.9542410714285714) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
